<template>
  <div class="w1092">
    <date-filter @search="loadList"
                 @update="onChangeDate"
                 :startDate="searchDate.startDate"
                 :endDate="searchDate.endDate"/>
    <ul class="wlbtn">
      <li>진행중인 금액: <span class="yec">78,308</span></li>
      <li>정산 총 금액: <span class="yec">24,393,246</span></li>
    </ul>

    <div class="board">
      <ul class="boardw first">
        <li>선택</li>
        <li>번호</li>
        <li>정산시작일</li>
        <li>정산종료일</li>
        <li>분류</li>
        <li>금액</li>
        <li>처리상태</li>
      </ul>
      <template v-if="list.length > 0">
        <template v-for="(item, index) in list" v-bind:key="pageInfo.page * index">
          <ul class="boardw">
            <li><input type="checkbox" v-model="item.checked"/></li>
            <li>{{pageInfo.page * (index + 1)}}</li>
            <li>{{item.startDate}}</li>
            <li>{{item.endDate}}</li>
            <li>{{item.gameName}}</li>
            <template v-if="item.paystatus === 'COMP'">
              <template v-if="item.summaryAmtPaid > 0">
                <li class="rdc">+{{thousand(item.summaryAmtPaid)}}</li>
              </template>
              <template v-else>
                <li class="blc">{{thousand(item.summaryAmtPaid)}}</li>
              </template>
              <li>정산완료</li>
            </template>
            <template v-else>
              <li>{{thousand(item.summaryAmtBalance)}}</li>
              <li>진행 중인 금액</li>
            </template>

          </ul>
        </template>
      </template>
      <template v-else>
      </template>

      <ul class="boardw winlosetotal">
        <li>합계</li>
        <li class="rdc" v-if="listSum > 0">+{{thousand(listSum)}}</li>
        <li v-else-if="listSum === 0">0</li>
        <li class="blc" v-else>{{thousand(listSum)}}</li>
      </ul>
    </div>

    <div class="boardbtn">
      <a class="grsbtn left" @click="onAllCheck">{{allChecked ? '전체해제': '전체선택'}}</a>
      <a class="orsbtn left ml5" @click="onRemoveSelect">선택삭제</a>
    </div>

    <pagination
        :pageNum="pageInfo.page"
        :pageSize="pageInfo.count_per_list"
        :totalCount="pageInfo.tatal_list_count"
        @goToPage="loadList"
    />
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'
import Pagination from '@/components/ui/Pagination'
import { getOnelooseList } from '@/api/retail'
export default {
  name: 'oneloose',
  components: { DateFilter, Pagination },
  data () {
    return {
      list: [],
      listSum: 0,
      allChecked: false
    }
  },
  created () {
    this.loadList()
  },
  methods: {
    loadList () {
      const params = {
        page: this.pageInfo.page || 1,
        startDate: this.searchDate.startDate,
        endDate: this.searchDate.endDate
      }

      getOnelooseList(params).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          const data = result.data
          this.list = data.list

          this.list.forEach(item => {
            item.checked = false
            if (item.paystatus === 'COMP') {
              this.listSum = this.listSum + item.summaryAmtPaid
            } else {
              this.listSum = this.listSum + item.summaryAmtBalance
            }

            let gameName = ''
            switch (item.groupCode) {
              case 'casino':
                gameName = '카지노'
                break
              case 'slot':
                gameName = '슬롯'
                break
              case 'sport':
                gameName = '스포츠'
                break
            }
            item.gameName = gameName
          })
        }
      })
    },
    onAllCheck (list) {
      this.allChecked = !this.allChecked
      this.list.forEach(item => {
        item.checked = this.allChecked
      })
    }
  }
}
</script>
<style scoped>
.wlbtn {display: flex;gap: 18px;margin-bottom: 18px;}
.wlbtn li {width: 100%;height: 50px;border-radius: 10px;border: solid 1px #1c594e;background-color: #f3f3f3;font-size: 16px;font-weight: bold;color: #1c594e;display: flex;justify-content: center;align-items: center;}
.wlbtn li span {font-size: 20px;color: #fc7220;margin-left: 20px;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
